import {
  DraftOrderUpdatePayload,
  createDraftOrdersDeliveries,
  createDraftOrdersQuotes,
  getSpecificDraftOrders,
  mergeDraftOrders,
  optimizeDraftOrders,
  patchDraftOrderQuoteId,
  patchDraftOrderRecipients,
  patchDraftOrders,
  patchDraftOrdersFleet,
} from "@/api/draftorder"
import { AbortControllerType } from "@/atoms/abortControllerAtom"
import { dispatchPageModalsState, DrawerOrder } from "@/atoms/dispatchPageModalsAtom"
import { IDraftOrdersAtom } from "@/atoms/draftOrdersAtom"
import { ServerDeliveryType } from "@/types/deliveryTypes.types"
import { IDispatchRule } from "@/types/dispatchRules.types"
import {
  DraftOrderStatus,
  DraftOrder,
  PatchDraftFleetOrdersRequest,
  PatchDraftOrdersRequest,
  isProcessing,
} from "@/types/draftOrder.types"
import { Location, OrderType } from "@/types/orders.types"
import { cloneDeep, isEmpty } from "lodash"
import { DateTime } from "luxon"
import { SetterOrUpdater } from "recoil"


export const pickupValidation = (
  t: any,
  sender?: Location,
) => {
  const pickupErrors = []
  const errorString = `${t("errors.draftUpload.missingSender")} `
  const errorArr: string[] = []
  if (!sender) {
    pickupErrors.push(errorString + t("common.address"))
    return pickupErrors
  }
  const { firstName, lastName, location } = sender
  const { address, latitude, longitude } = location || {}

  if (!sender.location?.id && !sender.location?.address) {
    errorArr.push(t("common.address"))
  }
  if (!firstName && !lastName) {
    errorArr.push(t("errors.draftUpload.name"))
  }

  if (!address || !latitude || !longitude) {
    errorArr.push(t("common.address"))
  }

  if (errorArr.length) {
    pickupErrors.push(errorString + errorArr.join(", "),)
  }

  return pickupErrors?.length > 0 ? pickupErrors : undefined
}

export const pickupTimeValidation = (
  t: any,
  schedule: number | undefined,
  deliveryType?: ServerDeliveryType,
  appliedRule?: IDispatchRule | undefined,
) => {
  const pickupTimeErrors = []

  const isInvalidSchedule = schedule === 0
  const isPastSchedule =
    schedule &&
    schedule > 0 &&
    DateTime.now().toMillis() > DateTime.fromMillis(schedule).toMillis()

  const deliveryTypeErrorString: string[] = []

  if (isInvalidSchedule) {
    deliveryTypeErrorString.push(t("errors.draftUpload.schedule"))
  } else if (isPastSchedule) {
    deliveryTypeErrorString.push(t("errors.draftUpload.pastSchedule"))
  }

  if (
    appliedRule?.deliveryType &&
    deliveryType &&
    deliveryType !== appliedRule?.deliveryType
  ) {
    deliveryTypeErrorString.push(t("errors.draftUpload.deliveryType"))
  }

  if (deliveryTypeErrorString.length > 0) {
    pickupTimeErrors.push(deliveryTypeErrorString.join("\n"))
  }

  return pickupTimeErrors?.length > 0 ? pickupTimeErrors : undefined
}

export const dropoffValidation = (
  t: any,
  recipients: Location[] | undefined,
) => {
  const dropoffErrors = []
  if (!recipients || recipients?.length === 0) {
    dropoffErrors?.push(t("errors.draftUpload.recipientError"))
    return dropoffErrors
  }

  for (const [index, recipient] of recipients.entries()) {
    const { firstName, lastName, location } = recipient
    const { address, latitude, longitude } = location || {}

    const errorString = `${t("errors.draftUpload.recipientError")} ${index + 1}: `
    const errorArr: string[] = []

    if (!firstName && !lastName) {
      errorArr.push(t("errors.draftUpload.name"))
    }
    if (!address || !latitude || !longitude) {
      errorArr.push(t("common.address"))
    }

    if (errorArr.length > 0) {
      dropoffErrors.push(errorString + errorArr.join(", "))
    }
  }

  return dropoffErrors?.length > 0 ? dropoffErrors : undefined
}

export const dropoffWarningValidation = (
  t: any,
  recipients: Location[] | undefined,
  isWarningActive?: boolean
) => {
  const dropoffWarnings = [] as string[]
  if (!recipients || recipients?.length === 0) {
    return dropoffWarnings
  }

  for (const [index, recipient] of recipients.entries()) {
    const errorString = `${t("errors.draftUpload.recipientWarning")} ${index + 1} `
    const errorArr: string[] = []

    if (recipient.expectedArrival?.to && recipient.expectedArrival?.to < DateTime.now().toMillis()) {
      errorArr.push(t("errors.draftUpload.arrivalBy"))
    }

    if (errorArr.length > 0) {
      dropoffWarnings.push(errorString + errorArr.join(", "))
    }

    if ((recipient.phone?.length || 0) < 4) {
      dropoffWarnings.push(`${t("errors.draftUpload.phone", { index: index + 1 })}`)
    }

    if (isWarningActive && recipient.location?.isWarning) {
      dropoffWarnings.push(`${t("errors.draftUpload.addressMayBeInaccurate", { index: index + 1 })}`)
    }
  }

  return dropoffWarnings?.length > 0 ? dropoffWarnings : undefined
}

export const updateDraftOrdersAtom = (
  updatedDraftOrders: DraftOrder[],
  setDraftOrders: SetterOrUpdater<IDraftOrdersAtom>,
  resetQuotes?: boolean
) => {
  setDraftOrders((draftState) => {
    let shouldResetQuote: string[] = []
    if (resetQuotes) {
      shouldResetQuote = [...(draftState?.shouldResetQuote || []), ...updatedDraftOrders?.map((order) => order.id)]
    }

    return {
      ...draftState,
      shouldResetQuote,
      orders: draftState.orders.map((order) => updatedDraftOrders.find((o) => o.id === order.id) || order),
    }
  })
}

export const updateDraftOrderAtom = (
  draftOrder: DraftOrder,
  setDraftOrders: SetterOrUpdater<IDraftOrdersAtom>,
  resetQuotes?: boolean
) => {
  setDraftOrders((d) => {
    const newDraftOrders = cloneDeep(d) as IDraftOrdersAtom
    const index = newDraftOrders.orders.findIndex((order) => order.id === draftOrder.id)

    if (index !== -1 && d.orders[index]) {
      newDraftOrders.orders[index] = draftOrder
    }

    return {
      ...newDraftOrders,
      ...(resetQuotes && {
        shouldResetQuote: [...d.shouldResetQuote, draftOrder.id],
      }),
    }
  })
}

export const handleUpdateRecipients = async (
  recipients: Location[] | null,
  draftOrderId: string,
  setDraftOrders: SetterOrUpdater<IDraftOrdersAtom>,
  autoSelectQuote: boolean = false,
  driverId?: string,
  optimize?: boolean
): Promise<DraftOrder> => {
  console.log("recipients", recipients)
  try {
    const { data } = await patchDraftOrderRecipients(draftOrderId, {
      recipients,
      autoSelectQuote,
      driverId,
      optimize
    })
    if (data) {
      updateDraftOrderAtom(data, setDraftOrders, true)
    }
    return data
  } catch (error: any) {
    throw error
  }
}

export const handleUpdateQuoteId = async (
  selectedQuoteId: string | null | undefined,
  draftOrderId: string,
  setDraftOrders: SetterOrUpdater<IDraftOrdersAtom>
) => {
  try {
    const { data } = await patchDraftOrderQuoteId(draftOrderId, selectedQuoteId)
    if (data) {
      updateDraftOrderAtom(data, setDraftOrders)
    }
  } catch (error) {
    throw (error)
  }
}

export const handleUpdateItems = async (
  payload: DraftOrderUpdatePayload,
  fields: PatchDraftOrdersRequest,
  setDraftOrders: SetterOrUpdater<IDraftOrdersAtom>,
  setDispatchPageModals?: SetterOrUpdater<dispatchPageModalsState>
) => {
  try {
    const { data } = await patchDraftOrders(payload, fields)
    updateDraftOrdersAtom(data, setDraftOrders, true)
    if (setDispatchPageModals) {
      const order = data.find((order) => order.id === payload.draftOrdersIds[0])
      setDispatchPageModals((dispatchPageState) => {
        if (dispatchPageState.editOrderDrawerInstance) {
          return { ...dispatchPageState, editOrderDrawerInstance: { ...order, type: OrderType.DRAFT } as DrawerOrder }
        } else {
          return dispatchPageState
        }
      })
    }
    return data
  } catch (err: any) {
    throw err
  }
}

export const handleUpdateFleet = async (
  payload: DraftOrderUpdatePayload,
  fields: PatchDraftFleetOrdersRequest,
  setDraftOrders?: SetterOrUpdater<IDraftOrdersAtom>
) => {
  try {
    const { data } = await patchDraftOrdersFleet(payload, fields)
    if (setDraftOrders) {
      updateDraftOrdersAtom(data, setDraftOrders, true)
    }
    return data
  } catch (err: any) {
    throw err
  }
}

export const handlePostDeliveries = async (
  draftOrders: IDraftOrdersAtom,
  payload: DraftOrderUpdatePayload,
  setDraftOrders: SetterOrUpdater<IDraftOrdersAtom>
) => {
  // validate if all items have a quote
  const confirmableOrders = payload?.draftOrdersIds?.filter((itemId) => {
    const item = draftOrders.orders.find((order) => order.id === itemId)
    return item?.bestQuote?.id || item?.metadata?.driverId || item?.metadata?.teamId || item?.metadata?.autoassignToAll
  })

  if (isEmpty(confirmableOrders) && !payload?.params?.all) {
    return
  }
  let blastAssignmentLimit;
  // save previous status
  const previousItemsState = draftOrders.orders.filter((order) => payload?.draftOrdersIds?.includes(order.id))
  // set status to ordering optimistically
  setDraftOrders((d) => {
    // TODO: Remove once in configuration
    blastAssignmentLimit = d.blastAssignmentLimit

    return {
      ...d,
      orders: d.orders.map((order) => ({
        ...order,
        status: confirmableOrders?.includes(order.id) ? DraftOrderStatus.ORDERING : order.status,
      })),
    }
  })
  try {
    const { data } = await createDraftOrdersDeliveries({ draftOrdersIds: confirmableOrders, params: payload?.params }, blastAssignmentLimit)
    updateDraftOrdersAtom(data, setDraftOrders)
  } catch (err) {
    console.log(err)
    // revert status
    setDraftOrders((d) => {
      return {
        ...d,
        orders: d.orders.map((order) => ({
          ...order,
          status: previousItemsState.find((o) => o.id === order.id)?.status || order.status,
        })),
      }
    })

    throw err
  }
}

export const handleRuleChange = async (
  draftOrders: IDraftOrdersAtom,
  affectedRows: string[],
  setDraftOrders: SetterOrUpdater<IDraftOrdersAtom>,
  rule?: string
) => {
  // save previous status
  const previousSelectedRows = draftOrders.orders.filter((order) => affectedRows.includes(order.id))
  // set status to quoting optimistically
  setDraftOrders((d) => {
    return {
      ...d,
      orders: d.orders.map((order) => ({
        ...order,
        status: affectedRows.includes(order.id) ? DraftOrderStatus.QUOTING : order.status,
      })),
    }
  })
  try {
    const { data } = await createDraftOrdersQuotes(affectedRows, rule)
    updateDraftOrdersAtom(data, setDraftOrders)
  } catch (err) {
    console.log(err)
    // revert status
    setDraftOrders((d) => {
      return {
        ...d,
        orders: d.orders.map((order) => ({
          ...order,
          status: previousSelectedRows.find((o) => o.id === order.id)?.status || order.status,
        })),
      }
    })
  }
}

export const handleMergeDraftOrders = async (
  itemIds: string[],
  setAbortController: SetterOrUpdater<AbortControllerType>
) => {
  try {
    const { data } = await mergeDraftOrders(itemIds)
    if (data) {
      setAbortController((controller) => {
        if (controller?.getDraftOrders?.abort) {
          controller?.getDraftOrders?.abort()
        }
        return { ...controller, getDraftOrders: null }
      })
    }
  } catch (err) {
    throw err
  }
}

export const handleOptimizeDraftOrders = async (
  payload: DraftOrderUpdatePayload,
  drivers: string[],
  startLocationId?: string,
) => {
  try {
    const { data } = await optimizeDraftOrders(payload, drivers, startLocationId)
    return data
  } catch (err) {
    throw err
  }
}

export const fetchProcessingDraftOrders = async (
  draftOrders: IDraftOrdersAtom,
  setDraftOrders: SetterOrUpdater<IDraftOrdersAtom>
) => {
  const processingOrderIds = draftOrders.orders
    .filter((o) => isProcessing(o.status))
    .map((order) => order.id)
  const { data } = await getSpecificDraftOrders(processingOrderIds)
  updateDraftOrdersAtom(data, setDraftOrders)
}
