import { DraftOrder, DraftOrdersGlobalMetadata } from "@/types/draftOrder.types"
import { atom, selector, selectorFamily } from "recoil"
import { formatMultipleDropoffs, formatToPickup } from "@/utils/stopsFormatter"
import { driversAtom } from "./driversAtom";

export const BLAST_DEFAULT_ASSIGNMENT_LIMIT = 3;

export interface IDraftOrdersAtom {
  orders: DraftOrder[]
  metadata: DraftOrdersGlobalMetadata
  shouldResetQuote: string[];
  blastAssignmentLimit: Record<number, number>;
  triggerRefresh: boolean
}

export const defaultGlobalMetadata: DraftOrdersGlobalMetadata = {
  byDriver: {},
  byZone: {},
  bySource: {},
  byLocationId: {},
  byPickupTime: {},
  total: 0,
  error: 0,
  warning: 0,
  totalReady: 0,
}

export const draftOrdersAtom = atom({
  key: "draftOrders",
  default: {
    orders: [] as DraftOrder[],
    metadata: defaultGlobalMetadata,
    shouldResetQuote: [],
    abortController: null,
    blastAssignmentLimit: {},
    triggerRefresh: false,
  } as IDraftOrdersAtom,
})

export const hasExistingDraftOrdersAtom = selector({
  key: "hasPendingDraftOrders",
  get: ({ get }) => {
    const { orders, metadata } = get(draftOrdersAtom)
    const drivers = get(driversAtom)
    return orders?.length > 0 || metadata?.total > 0 || drivers?.length > 0
  },
})

export const singleDraftOrderDetails = selectorFamily({
  key: "singleDraftOrderDetails",
  get:
    (draftOrderId) =>
      ({ get }) => {
        const { orders } = get(draftOrdersAtom)
        const selectedDraftOrder = orders?.find((order) => order.id === draftOrderId)
        return {
          dropOffDetails: selectedDraftOrder ? formatMultipleDropoffs(selectedDraftOrder?.recipients, selectedDraftOrder?.metadata?.deliveryType) : [],
          pickupDetails: selectedDraftOrder ? formatToPickup(selectedDraftOrder.sender, selectedDraftOrder?.metadata?.deliveryType) : undefined,
        }
      },
})
