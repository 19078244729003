import { DATE_FILTER_BY, DateFilterType } from '@/utils/datetime';
import { Driver } from './drivers.types';
import { BarcodeItem, DestinationPriority, DestinationStatuses, ExpectedArrivalType, Location, OrderType, Parcel, RecipientMetadata, StopType } from './orders.types'
import { OrderStatusesTypes } from './statuses.types';
import { ProvidersType } from './providers.types';
import { ServerDeliveryType } from './deliveryTypes.types';
import { DraftOrderStatus } from './draftOrder.types';
import { DateTime } from 'luxon';
import { KosmoDirections } from '@/hooks/useCalculateRoute';

export const UNASSIGNED_KEY_SLUG = "unassigned"

export interface DispatchMapFilters {
  statuses: string[];
  zones: string[];
  [OrderType.ACTIVE]: DateFilterType,
  [OrderType.DRAFT]: DateFilterType,
  teams: string[];
  drivers: string[];
  is_active: boolean;
  type: OrderType;
  dates: DateFilterType
  q?: string
  dateFilterBy?: DATE_FILTER_BY
  orgId?: string
  ttl?: number
  openedKeys?: string[]
}

export interface DispatchMapData {
  graph: Graph;
  driverPins: Record<string, DriverPin>;
  taskPins: Record<string, TaskPin>;
  type?: OrderType
  lastUpdatedAt?: DateTime
}

export interface DriverPin {
  driverID: string;
  coordinates: Coordinates;
  lastUpdatedAt: number;
}

export interface TaskPin {
  taskID: string;
  orderID: string;
  driverID: string;
  coordinates: Coordinates;
  taskKey?: string;
  recipient?: Location
  visible?: boolean;
  onPinClickOpen?: () => void
  onPinClickClose?: () => void
  onPinEditStopClick?: () => void
  adjustStopComponent?: (stop: { id: string; orderId: string; index: number }) => React.ReactElement
}

export interface Graph {
  unnassigned: OrderGraph;
  teams: TeamGraph[];
  thirdParty: OrderGraph;
}

export class GraphIterable implements Iterable<MapOrder> {
  constructor(private graph: Graph) { }

  *[Symbol.iterator](): Iterator<MapOrder> {
    yield* this.graph.unnassigned.o;

    for (const team of this.graph.teams) {
      yield* team.u.o;
      yield* team.b.o;
      for (const driver of team.d) {
        yield* driver.o.o;
      }
    }

    yield* this.graph.thirdParty.o;
  }

  map<T>(callback: (order: MapOrder) => T): T[] {
    const result: T[] = [];
    for (const order of this) {
      result.push(callback(order));
    }
    return result;
  }

  filter(predicate: (order: MapOrder) => boolean): MapOrder[] {
    const result: MapOrder[] = [];
    for (const order of this) {
      if (predicate(order)) {
        result.push(order);
      }
    }
    return result;
  }
}

export type RoutesDirections = {
  routes?: KosmoDirections
  stops: number[][],
  type?: OrderType
  newStopsOrder?: number[][]
  color?: string
}

export interface OrderGraph {
  t: number;
  c: number;
  f: number;
  r: number;
  tr: number;
  tw: number;
  o: MapOrder[];
}

export interface TeamGraph {
  id?: number;
  u: OrderGraph;
  b: OrderGraph;
  d: DriverGraph[];
  t: number;
  tr?: number
  tw: number;
}

export interface DriverGraph {
  id: string;
  o: OrderGraph;
}

export type MapOrder = MapActiveOrder | MapDraftOrder;

export interface ShortenedSender {
  id?: string,
  e?: string,
  l?: ShortenedLocationType
  ln?: string
  fn?: string
  p?: string
  n?: string
}

export interface ShortenedLocationType {
  id?: string
  // address
  a?: string
  // alias
  aa?: string
  // building number
  bn?: string
  // country
  cn?: string
  // city
  ct?: string
  // postal code
  pc?: string
  // Location
  lat?: number
  lon?: number
}

export interface ShortenedLocation {
  // id: 
  id?: string
  // barcodes
  b?: BarcodeItem[]
  // Location
  l?: ShortenedLocationType
  // Cash on delivery
  co?: number
  // Dropoff ETA
  de?: number
  // Planned Arrival At
  pla: number
  // email
  e?: string
  // expected Arrrival
  ea?: ExpectedArrivalType
  // expected time at stop
  et?: number
  // Finished at
  fa?: number
  // First Name
  fn?: string
  // Last Name
  ln?: string
  // Metadata:
  m?: RecipientMetadata
  // Notes
  notes?: string
  // order reference id
  o?: string
  // phone
  p?: string
  // Parcel
  pa?: Parcel
  // Parcels
  pas?: Parcel[] // TODO: enable this in API
  // TrackingURL
  tu?: string
  // status
  s?: DestinationStatuses
  // Stop type
  t?: StopType
  // Priority
  opt?: DestinationPriority
}

export interface ShortenedSchedule {
  p: number
}

export interface ShortenedTask {
  id: string;
  // Status
  s: DestinationStatuses;
  // Recipient
  r: ShortenedLocation;
  taskKey: string;
  driverID: string
}

export interface ShortenedContact {
  n: string // name
  p: string // phone
  nt: string // notes
  e: string // email
}

export interface ShortenedArrival {
  c: ShortenedContact
  l: ShortenedLocationType
  pla: number // Planned Arrival At
}

export interface BaseMapOrder {
  id: string;
  oid: string;
  p: ProvidersType;
  tt: ShortenedTask[];
  teamID?: string;
  driver?: Driver;
  assignmentType?: string;
  // Metadata
  m: ShortenedMetadata
  sn: ShortenedSender;
  dt: ServerDeliveryType
  // We receive it as a string from server
  rd?: string
  sch?: ShortenedSchedule
  // Created At
  ca: number
  pt?: number
  arr?: ShortenedArrival
}

export interface MapActiveOrder extends BaseMapOrder {
  // Stop Type
  t: OrderType.ACTIVE;
  // Status
  s: OrderStatusesTypes;
}

export interface MapDraftOrder extends BaseMapOrder {
  // Stop Type
  t: OrderType.DRAFT;
  // Status
  s: DraftOrderStatus;
}

export interface Task {
  id: string;
  s: DestinationStatuses;
  r: Location;
  taskKey: string;
  driverID: string
}

export interface Coordinates {
  latitude: number
  longitude: number
}

export interface ShortenedMetadata {
  // total distance
  td: number
  // end address
  ea: string
  // end latitude
  elat: number
  // end longitude
  elon: number
}
