import Geocode from "react-geocode"
import { Parcel, ParcelDimensions } from "@/types/orders.types"
import { parseParcelDimensions, parseParcelDimensionsV2 } from "@/utils/parcel"
import { CountryCode } from "libphonenumber-js"
import { isEmpty } from "lodash"
import { ServerDeliveryType } from "@/types/deliveryTypes.types"
import { EU_DATE_FORMAT_UPLOAD, EU_DATE_TIME_FORMAT_UPLOAD, parseDraftUploadDate } from "@/utils/datetime"
import { getCountry } from "countries-and-timezones"
import { DateTime } from "luxon"
import { splitFullName } from "@/utils/formatFullName"
import { formatPhoneNumber } from "@/utils/FormatPhoneNumber"
import { AddressDetails } from "@/hooks/useUploadTemplate"
import { KosmoGeocodeResult } from "@/types/here.types"
import { GeocodingOpts } from "@/hooks/useGeocoding"
import { ClientWithLocations } from "@/types/clients.types"

export const deliveryExcelTypes = {
  UNDEFINED: undefined,
  EMPTY: "",
  ASAP: "ASAP",
  SCHEDULE: "Schedule",
  FOUR_HOURS: "4 Hours",
  SAME_DAY: "Same Day",
  NEXT_DAY: "Next Day",
  NEXT_THREE_DAYS: "1 - 3 Days",
}

export const formatUploadedRecipientsFromTemplate = (
  addressDetails: AddressDetails,
  recipientAddress: string,
  recipientName?: string,
  recipientEmail?: string,
  buildingNumber?: string,
  recipientPhone?: string,
  dropOffNote?: string,
  orderReferenceId?: string,
  recipientCountry?: CountryCode,
  isWarning?: boolean
) => {
  const { firstName, lastName } = splitFullName(recipientName)

  const { postalCode, lat, lng } = addressDetails
  return {
    firstName: !!firstName ? `${firstName}` : "",
    lastName: !!lastName ? `${lastName}` : "",
    postalCode: `${postalCode}`,
    phone: recipientPhone ? formatPhoneNumber(recipientPhone, recipientCountry) : "",
    email: recipientEmail ? `${recipientEmail}` : "",
    location: {
      address: `${recipientAddress}`,
      postalCode: `${postalCode}`,
      latitude: Number(lat),
      longitude: Number(lng),
      buildingNumber: buildingNumber ? `${buildingNumber}` : "",
      country: recipientCountry ? `${recipientCountry}` : "",
      isWarning: isWarning,
    },
    orderReferenceId: orderReferenceId ? `${orderReferenceId}` : "",
    notes: dropOffNote ? `${dropOffNote}` : "",
  }
}
export const isEmptyCell = (cell: string | undefined) => {
  return cell === "" || cell === undefined || cell === null || cell === "NA"
}

export const getAddressDetail = async (
  geocodeByAddress: (
    queryAddress: string,
    opts: GeocodingOpts
  ) => Promise<KosmoGeocodeResult | undefined>,
  address: string,
  referenceCountry: CountryCode,
  referenceLatLng?: google.maps.LatLng,
  referencePostalCode?: string
) => {
  let postalCode, lat, lng, isWarning = false
  try {
    if (isEmptyCell(address)) {
      throw new Error("Invalid Address")
    }

    let result

    const longCountryName = getCountry(referenceCountry) ? getCountry(referenceCountry)!.name : undefined
    const targetAddress = `${address} ${longCountryName ?? ""}`
    result = await geocodeByAddress(targetAddress, {
      country: referenceCountry,
      ...(referenceLatLng ? { latLng: { lat: referenceLatLng?.lat(), lng: referenceLatLng?.lng() } } : undefined),
      postalCode: referencePostalCode,
      forceGoogle: !referenceLatLng?.lat() && !referenceLatLng?.lng()
    })
    if (!result) {
      postalCode = ""
      lat = 0
      lng = 0
      throw new Error("Invalid Address")
    }

    postalCode = result?.address?.postalCode
    lat = result?.location.lat
    lng = result?.location.lng
    isWarning = result?.isWarning
  } catch {
    postalCode = ""
    lat = 0
    lng = 0
  }
  return { postalCode, lat, lng, isWarning }
}

export const getReverseAddress = async (lat: number, lng: number) => {
  const response = await Geocode.fromLatLng(`${lat}`, `${lng}`)
  if (response?.results.length === 0) {
    return ""
  }
  const address = response.results[0].formatted_address
  return address
}


export const formatUploadedParcelDetails = (
  parcelValue?: string,
  parcelDimensions?: string,
  parcelDimensionsV2?: string,
  parcelWeight?: string,
  parcelLength?: string,
  parcelHeight?: string,
  parcelWidth?: string,
  parcelQuantity?: string,
  parcelDescription?: string,
  barcode?: string
): Parcel | undefined => {
  if (
    isEmptyCell(parcelValue) &&
    isEmptyCell(parcelDimensions) &&
    isEmptyCell(parcelDimensionsV2) &&
    isEmptyCell(parcelWeight) &&
    isEmptyCell(parcelLength) &&
    isEmptyCell(parcelHeight) &&
    isEmptyCell(parcelWidth) &&
    isEmptyCell(parcelQuantity) &&
    isEmptyCell(parcelDescription) &&
    isEmptyCell(barcode)
  ) {
    return undefined
  }

  const defaultDimensions = {
    ...(!isEmptyCell(parcelWeight) ? { weight: Number(parcelWeight) } : { weight: 0 }),
    ...(!isEmptyCell(parcelHeight) ? { height: Number(parcelHeight) } : { height: 0 }),
    ...(!isEmptyCell(parcelLength) ? { length: Number(parcelLength) } : { length: 0 }),
    ...(!isEmptyCell(parcelWidth) ? { width: Number(parcelWidth) } : { width: 0 }),
  }

  const dimensions: ParcelDimensions = {
    ...(!isEmptyCell(parcelDimensions) && parcelDimensions
      ? { ...parseParcelDimensions(parcelDimensions), ...defaultDimensions }
      : defaultDimensions),
    ...(!isEmptyCell(parcelDimensionsV2) && parcelDimensionsV2
      ? { ...parseParcelDimensionsV2(parcelDimensionsV2), ...defaultDimensions }
      : defaultDimensions),
  }

  return {
    ...(!isEmpty(dimensions) ? { dimensions: dimensions } : {}),
    ...(!isEmptyCell(parcelValue) ? { value: Number(parcelValue) } : {}),
    ...(!isEmptyCell(parcelQuantity) ? { quantity: Number(parcelQuantity) } : {}),
    ...(!isEmptyCell(parcelDescription) ? { description: parcelDescription } : {}),
    ...(!isEmptyCell(barcode) && !isEmpty(barcode) ? { barcode: { id: barcode!, createdAt: DateTime.now().toMillis() } } : {}),
  }
}

export const getDeliveryTypeAndScheduleFromTemplate = (deliveryType: string, date: string, scheduledTime: string) => {
  const parsedDate = parseDraftUploadDate(date, scheduledTime)
  let quoteDeliveryType = ServerDeliveryType.INSTANT
  let schedule
  switch (deliveryType) {
    case deliveryExcelTypes.NEXT_DAY:
      quoteDeliveryType = ServerDeliveryType.NEXT_DAY
      break
    case deliveryExcelTypes.NEXT_THREE_DAYS:
      quoteDeliveryType = ServerDeliveryType.NEXT_THREE_DAYS
      break
    case deliveryExcelTypes.ASAP:
      quoteDeliveryType = ServerDeliveryType.INSTANT
      break
    case deliveryExcelTypes.SCHEDULE:
      quoteDeliveryType = ServerDeliveryType.INSTANT
      if (parsedDate && parsedDate.isValid) {
        schedule = {
          pickup_at: parsedDate.toMillis(),
          pickupAt: parsedDate.toMillis(),
        }
      }
      break
    case deliveryExcelTypes.FOUR_HOURS:
      quoteDeliveryType = ServerDeliveryType.FOURHOURS
      if (date?.includes("ASAP") && scheduledTime?.includes("ASAP")) {
        if (parsedDate && parsedDate.isValid) {
          schedule = {
            pickup_at: parsedDate.toMillis(),
            pickupAt: parsedDate.toMillis(),
          }
        }
      }
      break
    case (deliveryExcelTypes.EMPTY, deliveryExcelTypes.UNDEFINED):
      quoteDeliveryType = ServerDeliveryType.EMPTY
      if (date?.includes("ASAP") && scheduledTime?.includes("ASAP")) {
        break
      }
      // ASAP + Scheduled time
      if (date?.includes("ASAP") && !scheduledTime?.includes("ASAP")) {
        const today = DateTime.now().startOf("day").toFormat(EU_DATE_FORMAT_UPLOAD)
        const dateAndTime = DateTime.fromFormat(`${today} ${scheduledTime}`, EU_DATE_TIME_FORMAT_UPLOAD)
        if (dateAndTime.isValid) {
          schedule = {
            pickup_at: dateAndTime.toMillis(),
            pickupAt: dateAndTime.toMillis(),
          }
        }
      }
      if (parsedDate && parsedDate.isValid) {
        schedule = {
          pickup_at: parsedDate.toMillis(),
          pickupAt: parsedDate.toMillis(),
        }
      }
      break
    default:
      break
  }
  return { quoteDeliveryType, schedule }
}

type UploadedRecipient = {
  name?: string
  address?: string
  postalCode?: string,
  phone?: string
  buildingNumber?: string,
  dropOffNote?: string
  latitude?: number,
  longitude?: number
  country?: string
  email?: string
}

export const getCleanUploadedRecipient = ({ name, address, postalCode, country, phone, buildingNumber, dropOffNote, latitude, longitude, email }: UploadedRecipient, client?: ClientWithLocations) => {
  const clientHasLocation = !!client && !!client?.locations?.[0]

  const hasRecipientData =
    (client && clientHasLocation) ||
    name ||
    address ||
    buildingNumber ||
    phone ||
    dropOffNote
  if (!hasRecipientData) {
    return undefined
  }

  const parsedPostalCode =
    clientHasLocation && client?.locations?.[0]?.postalCode
      ? client?.locations?.[0]?.postalCode
      : address?.match(/\b\d{5}\b/)
  // TODO: Dirty but this concatenates address components if filled to increase geocode accuracy
  let finalAddress =
    clientHasLocation && client?.locations?.[0]?.address
      ? client?.locations?.[0]?.address
      : `${address}${postalCode ? " " + postalCode : ""}`

  if (!postalCode && parsedPostalCode?.[0]) {
    finalAddress = `${parsedPostalCode?.[0]} ${finalAddress}`
  }

  const finalLatitude =
    clientHasLocation && client?.locations?.[0]?.latitude
      ? client?.locations?.[0]?.latitude
      : latitude

  const finalLongitude =
    clientHasLocation && client?.locations?.[0]?.longitude
      ? client?.locations?.[0]?.longitude
      : longitude

  const finalCountry =
    clientHasLocation && client?.locations?.[0]?.country
      ? client?.locations?.[0]?.country
      : country

  const finalName = client ? client?.name : name

  return {
    name: finalName,
    address: finalAddress,
    country: finalCountry as CountryCode,
    buildingNumber: String(buildingNumber),
    phone: String(phone),
    latitude: finalLatitude || 0,
    longitude: finalLongitude || 0,
    postalCode: parsedPostalCode,
    dropOffNote: String(dropOffNote),
    email: String(email),
  }
}
