import { DateTime } from "luxon"
import { Coordinates } from "./map"
import { VehicleType } from "./vehicle.types"

export interface DriverAttachment {
  expiresAt: number
  name: string
  url: string
}


export interface DriverScheduleWeekday {
  enabled: boolean;
  start?: string; // HH:mm
  end?: string; // HH:mm
}

export type DriverSchedule = {
  weekdays: Record<string, DriverScheduleWeekday>
}

export type DriverStatus = "online" | "offline" | "idle" | "late"


export interface Driver {
  id: string
  name: string
  phone: string
  email: string
  vehicleType: VehicleType
  lengthCapacity?: number
  heightCapacity?: number
  widthCapacity?: number
  weightCapacity?: number
  locationID?: string
  teamIDs?: number[]
  status: DriverStatus
  plateNumber?: string,
  photoUrl?: string,
  latitude?: number,
  longitude?: number,
  locationUpdatedAt?: number,
  lastUpdated?: number,
  config?: OwnFleetDriverConfig
  preventAnimation?: boolean
  disabled?: boolean
  attachments?: DriverAttachment[]
  schedule?: DriverSchedule
}

export interface EnrichedDriver extends Driver {
  ongoingDeliveries?: number
}

export interface DriverMapPin extends Driver {
  onDriverPinClick?: () => void
  onDriverPinCloseClick?: () => void
}

export interface OwnFleetDriverConfig {
  routeStartAddress?: string
  routeStartLocation?: Coordinates
  routeEndAddress?: string
  routeEndLocation?: Coordinates
  maxBatchSize?: number
  maxDistanceInMeters?: number
  maxShiftTimeInMinutes?: number
}


export type DriverScheduleWeekdayFormValues = {
  enabled: boolean
  start?: DateTime
  end?: DateTime
}

export type DriverScheduleFormValues = {
  weekdays: Record<string, DriverScheduleWeekdayFormValues>
}

export type DriverFormValues = Driver & {
  defaultRouteStartLocation?: boolean
  routeStartAddress?: string
  routeStartLocation?: Coordinates
  defaultRouteEndLocation?: boolean
  routeEndAddress?: string
  routeEndLocation?: Coordinates
  defaultMaxBatchSize?: boolean
  maxBatchSize?: number
  defaultMaxDistance?: boolean
  maxDistanceInKm?: number
  defaultMaxShiftTime?: boolean
  maxShiftTime?: {
    hours: number
    minutes: number
  }
  schedule?: DriverScheduleFormValues
}


export enum DriverFormTab {
  PROFILE = "profile",
  CONFIG = "config",
  SCHEDULE = "schedule",
  DOCUMENTS = "documents"
}


type ActiveDriver = {
  id: string
  status: DriverStatus
}

type ActiveDriverTimeGroup = {
  time: string
  drivers: ActiveDriver[]
}

type ActiveDriverTeamGroup = {
  teamId: number
  starting: ActiveDriverTimeGroup[]
  online: ActiveDriver[]
  ending: ActiveDriverTimeGroup[]
}

export type ActiveDriversData = {
  teams: ActiveDriverTeamGroup[]
}
