import DisabledTooltip from "@/UI/DisabledTooltip"
import SearchFilter from "@/UI/Filters/SearchFilter"
import { locationsSelector } from "@/atoms/userAtom"
import AddLocationModal from "@/modules/profile/LocationSettings/components/AddLocationModal"
import { PickupDetailsType } from "@/types/createSingleOrder.types"
import { matchField } from "@/utils/compareObjects"
import { faChevronDown, faCirclePlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Select, Divider, Space, Button } from "antd"
import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useRecoilValue } from "recoil"

interface SelectPickupDropdownProps {
  selectedStore: string | null
  handleStore: (id: string) => Promise<void>
  disabled: boolean
  loading: boolean
  isShortDescription?: boolean
  handleClearPickups?: () => Promise<void>
  onClear?: () => void
  isOptional?: boolean
  showEmptyButton?: boolean
}

const SelectPickupDropdown: React.FC<SelectPickupDropdownProps> = ({
  selectedStore,
  handleStore,
  disabled,
  loading,
  handleClearPickups,
  onClear,
  isShortDescription,
  isOptional,
  showEmptyButton = false,
}) => {
  const { t } = useTranslation()
  const locations = useRecoilValue(locationsSelector)
  const [searchValue, setSearchValue] = useState("")
  const [filteredStores, setFilteredStores] = useState<PickupDetailsType[]>([])
  useEffect(() => {
    setFilteredStores(
      locations?.filter((store) => {
        return (
          !searchValue ||
          matchField(store.name, searchValue) ||
          matchField(store?.address, searchValue) ||
          matchField(store?.alias, searchValue) ||
          matchField(store?.postalCode as string, searchValue)
        )
      })
    )
  }, [searchValue, locations])
  const storeOptions = filteredStores?.map((store) => ({
    value: store.id,
    label: (
      <div className="flex gap-x-2" title={store?.address}>
        {store?.alias ? (
          <p className="font-semibold">{store.alias}</p>
        ) : (
          <p className="font-semibold">{store.name}</p>
        )}
        {!isShortDescription && (
          <p className="text-ellipsis overflow-hidden max-w-[250px]">
            {store.address?.split(",", 2)?.join(",")}
          </p>
        )}
      </div>
    ),
  }))

  return (
    <DisabledTooltip
      enable={disabled}
      label={t("new-orders.DraftOrderTable.pickupSelectDisabled")}
      placement={"bottomLeft"}
    >
      {locations?.length === 0 && showEmptyButton ? (
        <Button>
          <Space style={{ padding: "0 8px 4px" }}>
            <AddLocationModal
              button={
                <div className="flex cursor-pointer">
                  <FontAwesomeIcon
                    className="flex flex-shrink-0 h-3 mx-auto my-auto text-kosmoBlue ml-1 mr-2"
                    icon={faCirclePlus}
                  />
                  <p> {t("profile.AddStoreModal.addNewLocation")}</p>
                </div>
              }
            />
          </Space>
        </Button>
      ) : (
        <Select
          size="middle"
          className="text-sm draft-order-rule-selector"
          onClick={(e) => e.stopPropagation()}
          placeholder={
            isOptional ? (
              <p className="text-base">
                {`${t("new-orders.BatchSelectors.selectPickupStart")} (${t("common.optional")})`}
              </p>
            ) : (
              <p className="text-base">{t("new-orders.BatchSelectors.selectPickupStart")}</p>
            )
          }
          value={selectedStore}
          onChange={handleStore}
          disabled={disabled}
          suffixIcon={
            <FontAwesomeIcon
              size="xs"
              className="text-gray-700"
              icon={faChevronDown}
              color="#6B7280"
            />
          }
          onClear={onClear}
          allowClear={!!onClear}
          loading={loading}
          options={storeOptions}
          getPopupContainer={() => document.getElementById("draft-order-container") as any}
          dropdownRender={(menu) => (
            <>
              <div className="flex items-center space-x-5 px-2.5 py-2">
                <div className="flex flex-grow">
                  <SearchFilter
                    searchValue={searchValue}
                    handleSearchValue={(value) => setSearchValue(value)}
                    size="middle"
                    searchWidth="100%"
                  />
                </div>
                {handleClearPickups && (
                  <p
                    onClick={handleClearPickups}
                    className="cursor-pointer hover:underline hover:text-kosmoPrimaryBlue"
                  >
                    {t("common.clearAll")}
                  </p>
                )}
              </div>
              {menu}
              <Divider style={{ margin: "8px 0" }} />
              <Space style={{ padding: "0 8px 4px" }}>
                <AddLocationModal
                  button={
                    <div className="flex cursor-pointer">
                      <FontAwesomeIcon
                        className="flex flex-shrink-0 h-3 mx-auto my-auto text-kosmoBlue ml-1 mr-2"
                        icon={faCirclePlus}
                      />
                      <p> {t("profile.AddStoreModal.addNewLocation")}</p>
                    </div>
                  }
                />
              </Space>
            </>
          )}
          popupMatchSelectWidth={false}
        />
      )}
    </DisabledTooltip>
  )
}

export default SelectPickupDropdown
