import React from "react"
import { Form, Switch, Row, Col } from "antd"
import { useTranslation } from "react-i18next"
import { SemiBoldLabelText } from "@/UI"
import { DriverSchedule } from "@/types/drivers.types"
import { DateTime } from "luxon"
import LuxonDatePicker from "@/modules/shared/LuxonDatePicker"
import { isEmpty } from "lodash"

const daysOfWeek = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]

export type DriverScheduleFormProps = {
  schedule?: DriverSchedule
}

const { TimePicker } = LuxonDatePicker

const DriverScheduleForm: React.FC<DriverScheduleFormProps> = ({ schedule }) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col gap-y-4 mb-4">
      <SemiBoldLabelText>{t("profile.DriversSettings.schedule")}</SemiBoldLabelText>
      {daysOfWeek.map((day) => (
        <Form.Item key={day} className="mb-0">
          <Row gutter={16} align="middle">
            <Col span={8}>
              <Form.Item
                name={["schedule", "weekdays", day, "enabled"]}
                valuePropName="checked"
                noStyle
              >
                <Switch />
              </Form.Item>
              <span className="ml-2 capitalize">{t(`common.${day}`)}</span>
            </Col>
            <Col span={16}>
              <Row gutter={8}>
                <Col span={12}>
                  <Form.Item name={["schedule", "weekdays", day, "start"]} noStyle>
                    <TimePicker
                      format="HH:mm"
                      placeholder={t("common.start")}
                      className="w-full"
                      disabled={!Form.useWatch(["schedule", "weekdays", day, "enabled"])}
                      disabledTime={() => {
                        return {
                          disabledMinutes: () =>
                            Array.from({ length: 60 }, (_, i) => i).filter(
                              (min) => ![0, 15, 30, 45].includes(min)
                            ),
                        }
                      }}
                      hideDisabledOptions
                      defaultValue={
                        !isEmpty(schedule?.weekdays?.[day]?.start)
                          ? DateTime.fromISO(schedule?.weekdays?.[day]?.start || "")
                          : undefined
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name={["schedule", "weekdays", day, "end"]} noStyle>
                    <TimePicker
                      format="HH:mm"
                      placeholder={t("common.end")}
                      className="w-full"
                      disabled={!Form.useWatch(["schedule", "weekdays", day, "enabled"])}
                      disabledTime={() => {
                        return {
                          disabledMinutes: () =>
                            Array.from({ length: 60 }, (_, i) => i).filter(
                              (min) => ![0, 15, 30, 45].includes(min)
                            ),
                        }
                      }}
                      hideDisabledOptions
                      defaultValue={
                        !isEmpty(schedule?.weekdays?.[day]?.end)
                          ? DateTime.fromISO(schedule?.weekdays?.[day]?.end || "")
                          : undefined
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form.Item>
      ))}
    </div>
  )
}

export default DriverScheduleForm
