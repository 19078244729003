import { OrderStatusesTypes } from "@/types/statuses.types"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { isEmpty, pick } from "lodash"
import { IssueTypes } from "@/types/issues.types"
import { ACTIVE_ORDERS_LIMIT_VALUE } from "@/modules/active-orders/shared/constants"
import { DateTime } from "luxon"
import { DraftOrderTableStatusFilter } from "@/types/draftOrder.types"
import {
  DateFilterType,
  DATE_FILTER_BY,
  EU_DATE_FORMAT,
  DATE_FORMAT_REGIONS,
  LuxonToMillisFormats,
  REGION_LUXON_PICKER_DATE_FORMAT,
} from "@/utils/datetime"
import { TaskFailureReason } from "@/types/taskFailureReasons.types"
import { OrderType } from "@/types/orders.types"

export interface IFiltersState {
  isActive?: boolean
  q?: string
  status?: OrderStatusesTypes | null
  failureReasons?: TaskFailureReason[]
  issue?: IssueTypes | null
  dates: DateFilterType
  offset?: number
  limit?: number
  zoneIds?: string[]
  couriers?: string[]
  teams?: number[]
  driversIds?: string[]
  plan?: boolean
  draftStatus: DraftOrderTableStatusFilter
  locationIds?: string[]
  sources?: string[]
  all?: boolean
  dateFilterBy: DATE_FILTER_BY
  historyOrdersDateFilterBy?: DATE_FILTER_BY
  pickupTimes?: number[]
  type?: OrderType
}

type IUseOrdersFilters = {
  filters: IFiltersState
  setFilters: Dispatch<SetStateAction<IFiltersState>>
}

export const ACTIVE_ORDERS_DEFAULT_DATES: DateFilterType = {
  range: [
    DateTime.now().startOf("day").toFormat(EU_DATE_FORMAT),
    DateTime.now().endOf("day").toFormat(EU_DATE_FORMAT),
  ],
  format: EU_DATE_FORMAT,
}
export const getActiveOrdersDefaultDates = (
  preferredDateFormat?: DATE_FORMAT_REGIONS
): DateFilterType => {
  const format = REGION_LUXON_PICKER_DATE_FORMAT[preferredDateFormat || DATE_FORMAT_REGIONS.EU]
  return {
    range: [
      DateTime.now().startOf("day").toFormat(format),
      DateTime.now().endOf("day").toFormat(format),
    ],
    format: format as LuxonToMillisFormats,
  }
}

export const ANALYTICS_ORDERS_DEFAULT_DATES: DateFilterType = {
  range: [
    DateTime.now().minus({ days: 7 }).toFormat(EU_DATE_FORMAT),
    DateTime.now().endOf("day").toFormat(EU_DATE_FORMAT),
  ],
  format: EU_DATE_FORMAT,
}
export const getAnalyticsOrdersDefaultDates = (
  preferredDateFormat?: DATE_FORMAT_REGIONS
): DateFilterType => {
  const format = REGION_LUXON_PICKER_DATE_FORMAT[preferredDateFormat || DATE_FORMAT_REGIONS.EU]
  return {
    range: [
      DateTime.now().minus({ days: 7 }).toFormat(format),
      DateTime.now().endOf("day").toFormat(format),
    ],
    format: format as LuxonToMillisFormats,
  }
}

export const getDraftOrdersDefaultDates = (
  preferredDateFormat?: DATE_FORMAT_REGIONS
): DateFilterType => {
  const format = REGION_LUXON_PICKER_DATE_FORMAT[preferredDateFormat || DATE_FORMAT_REGIONS.EU]
  return {
    range: [
      DateTime.now().minus({ week: 1 }).startOf("day").toFormat(format),
      DateTime.now().endOf("day").toFormat(format),
    ],
    format: format as LuxonToMillisFormats,
  }
}

export const DRAFT_ORDERS_DEFAULT_DATES: DateFilterType = {
  // Filters are initialised with range format to be displayable - LUXON format are only used for calculation purposes
  range: [
    DateTime.now().minus({ week: 1 }).startOf("day").toFormat(EU_DATE_FORMAT),
    DateTime.now().endOf("day").toFormat(EU_DATE_FORMAT),
  ],
  format: EU_DATE_FORMAT,
}

export const defaultFilters: IFiltersState = {
  limit: ACTIVE_ORDERS_LIMIT_VALUE,
  offset: 0,
  teams: [],
  couriers: [],
  zoneIds: [],
  driversIds: [],
  dates: ACTIVE_ORDERS_DEFAULT_DATES,
  draftStatus: DraftOrderTableStatusFilter.TOTAL,
  dateFilterBy: DATE_FILTER_BY.CREATION,
}

export const useOrdersFilters = (
  initialFilters?: IFiltersState,
  useCache: boolean = true
): IUseOrdersFilters => {
  let state: IFiltersState
  if (initialFilters && !isEmpty(initialFilters)) {
    state = { ...defaultFilters, ...initialFilters }
  } else {
    state = { ...defaultFilters }
  }

  const cachedFilters = JSON.parse(localStorage?.getItem("couriersFilters") || "{}")
  if (useCache && !isEmpty(cachedFilters)) {
    state = { ...state, ...pick(cachedFilters, ["teams", "driversIds", "couriers"]) }
  }

  const [filters, setFilters] = useState<IFiltersState>(state)

  useEffect(() => {
    if (!useCache) {
      return
    }
    localStorage?.setItem(
      "couriersFilters",
      JSON.stringify(pick(filters, ["teams", "driversIds", "couriers"]))
    )
  }, [filters?.teams, filters?.driversIds, filters?.couriers])

  return { filters, setFilters }
}
